.prRatings {
  margin-bottom: 10px !important;
  min-height: 28px;
}
.prRatings .review {
  display: inline-block;
  vertical-align: bottom;
  
}
.pr-snippet-rating-decimal {
  display: none !important;
}
.pr-category-snippet__total {
  display: none !important;
}
.pr-rating-stars .pr-star-v4-100-filled {
  background-image: url('/images/star-active.svg') !important;
}
.pr-rating-stars .pr-star-v4-0-filled {
  background-image: url('/images/star-disabled.svg') !important;
}
.pr-rating-stars .pr-star-v4-25-filled {
  background-image: url('/images/star-25.svg') !important;
}
.pr-rating-stars .pr-star-v4-50-filled {
  background-image: url('/images/star-50.svg') !important;
}
.pr-rating-stars .pr-star-v4-75-filled {
  background-image: url('/images/star-75.svg') !important;
}
.prRatings .review  .p-w-r {
  margin-left: 0px !important;
}
.prRatings .review .p-w-r {
  -webkit-font-smoothing: subpixel-antialiased !important;
  color: #000;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
.prRatings .review .p-w-r {
  display: block !important;
}
.prRatings .review .p-w-r section {
  display: block;
  border: 0;
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
.prRatings .review .p-w-r * {
  box-sizing: border-box;
}
.prRatings .review .p-w-r .pr-snippet div, .prRatings .review .p-w-r .pr-snippet span {
  vertical-align: middle;
}
.prRatings .review .p-w-r .pr-category-snippet__item {
  display: table-cell;
  vertical-align: middle;
}
.prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png {
  display: inline-block;
}
.prRatings .review .p-w-r .pr-rating-stars {
  display: inline-block;
  line-height: normal;
}
.prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-rating-stars {
  position: relative;
}
.prRatings .review *, .prRatings .review :after, .prRatings .review :before {
  --pr-ring-inset: var(--pr-empty);
 /*!*/
 /*!*/
  --pr-ring-offset-width: 0px;
  --pr-ring-offset-color: #fff;
  --pr-ring-color: rgba(59, 130, 246, 0.5);
  --pr-ring-offset-shadow: 0 0 transparent;
  --pr-ring-shadow: 0 0 transparent;
}
.prRatings .review *, .prRatings .review :after, .prRatings .review :before {
  --pr-shadow: 0 0 transparent;
}
.prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4, .prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star, .prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-0-filled, .prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-25-filled, .prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-50-filled, .prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-75-filled, .prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-100-filled, .prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-half, .prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-selected, .prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4, .prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-0-filled, .prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-25-filled, .prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-50-filled, .prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-75-filled, .prRatings .review .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-100-filled {
  border-radius: 3px;
  height: 20px;
  margin-left: 0;
  margin-right: 2px;
  padding: 3px;
  width: 20px;
}
.prRatings .review .p-w-r .pr-star-v4, .prRatings .review .p-w-r .pr-star-v4-100-filled {
  background-size: cover;
  display: inline-block;
  height: 50px;
  line-height: normal;
  margin-right: 5px;
  width: 50px;
}
.prRatings .review .pr-accessible-text {
  display: none;
}
.prRatings .review .pr-category-snippet__total {
  display: none !important;
}
.prRatings #review-counts {
  vertical-align: sub;
}
 .counts {
  display: inline-block;
  vertical-align: sub;
  padding-top: 4px;
}

.p-w-r .pr-snippet .pr-snippet-stars-png .pr-star, .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-0-filled, .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-100-filled, .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-25-filled, .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-50-filled, .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-75-filled, .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-half, .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-selected, .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4, .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-0-filled, .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-100-filled, .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-25-filled, .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-50-filled, .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-75-filled{
  border-radius: 3px;
  height: 16px !important;
  margin-left: 0;
  margin-right: 2px !important;
  padding: 0px;
  width: 16px !important;
}
.pr-snippet-rating-decimal{
    background-color:#000;
    color:#fff!important;
    padding:0.25rem 0.5rem!important;
    margin-left:0.625rem!important;
    border:none!important;
  }
  
  .pr-irlsnippet {
    display: none !important;
  }
  header.pr-review-snapshot-header{
    display: none !important;
  }
  
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-snippet-rating-decimal{
    display: none !important;
  }
  .p-w-r .pr-snippet-stars-reco-inline {
    width: 100%; 
    /* display: inline-flex; */
    flex-direction: row;
    flex-wrap: wrap;
    /* border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.3rem 0 0.8rem; */
  }
  .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-stars-reco-reco{
    float: inherit !important;
    margin-top: 0;
  }
  .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-stars {
    float: left !important;
    margin-top: 7px;
}
  .p-w-r .pr-snippet-reco-to-friend .pr-reco span.pr-reco-value{
    font-size: 13px;
    color: #000;
  }
 
  .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-stars{
    order: 2;
    flex: 1 0 100%;
  }
  
  .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-stars-reco-reco{
    margin-top:0px;
  }
  .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-stars-reco-reco .pr-snippet-reco-to-friend{
    display: table-cell;
  }
  .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-stars-reco-reco .pr-snippet-reco-to-friend .pr-snippet-reco-to-friend-percent{
    vertical-align: middle;
  }
  .p-w-r .pr-review-snippet-container .pr-snippet-slider-root{
    display: none !important;
  }
  .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-review-count {
    text-decoration: underline !important;
    color: #000
  }
  .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-review-count:hover {
    text-decoration: none !important;
    opacity: 0.9;
  }
  .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-write-review-link {
    display: none;
  }
  .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-reco-to-friend .pr-reco-to-friend-message {
    text-indent: -9999px;
    vertical-align: inherit;
  }
  .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-reco-to-friend .pr-reco-to-friend-message::after {
    /* content: "Customer Recommended"; */
    content: "Recommended";
    text-indent: 0px;
    display: block;
    font-size: 13px;
    color: #000;
  }
  @-moz-document url-prefix() {
    .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-reco-to-friend .pr-reco-to-friend-message::after {
      /* content: "Customer Recommended";  */
      content: "Recommended";
      display: block ruby;
      
    }
  }
  .p-w-r .pr-snippet-reco-to-friend span .pr-checkbox-icon{
    display: none !important;
  }
  
  .p-w-r .pr-review .pr-snippet-rating-decimal{
    display:none!important;
  }
  .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-read-and-write{
    margin-top: 4px;
  }
  .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-stars-container{
    display: inline-block;
  }
  /* css for review shapshot*/
  
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot{
    background-color: #f6f6f6;
  
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block-container .pr-review-snapshot-block:first-child {
    padding-left: 20px;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot-block-snippet{
    padding-left: 20px;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-review-snapshot-simple { 
    display: block; 
  }
  #pr-reviewSnapShot .p-w-r .pr-snippet-reco-to-friend div.pr-snippet-reco-to-friend-percent{
    vertical-align: middle !important;
  }
  
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-block{
    background-color: #fff;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets{
    width: 100%;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a{
    background-color: #fff;
    border: 1px solid #eee;
    width: 100%;
    text-transform: uppercase;
    box-shadow: 2px 2px #b4b4b4;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a:hover{
    background-color: #fff; 
    border: 1px solid #eee;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-review-snapshot-faceoff{
    display: none;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block-container .pr-review-snapshot-block{
    background-color: #fff; 
    width: 100%;
    
  } 
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-simple .pr-review-snapshot-block{
    width: 31.6%;
    margin: 10px; 
    border: 1px solid #eee;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets-headline {
    color: #4a4a4a;
    font-size: 35px;
    margin-bottom: 0.3rem;
    text-align: center; 
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets-headline span{
    font-size: 35px;
    vertical-align: middle;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets-headline span.recText{
    font-size: 14px;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-ratings-histogram-barValue {
    background: #000000;
     
  }
  #pr-reviewSnapShot .p-w-r .pr-ratings-histogram-barValue {
    background: linear-gradient(#000000,#000000); 
  }
  #pr-reviewSnapShot .p-w-r .pr-ratings-histogram-barValue {
    background: #000000; 
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block .pr-ratings-histogram-bar { 
    border-radius: 0; 
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block .pr-ratings-histogram-barValue {
    border-radius: 0;
    height: 15px;
  }
  
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-histogram-msq-container {
    width: 31.6%;
    margin: 10px;
    float: left;
    padding: 10px;
    min-height: 210px;
    border: 1px solid #eee; 
    background-color: #fff; 
    margin-top: 0;
    
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-histogram-msq-container.pr-review-snapshot-histogram-msq-simple .pr-review-snapshot-msq-histogram:nth-child(3n-2){
        padding-left: 0px;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-histogram-msq-container .pr-review-snapshot-msq-histogram {
    border-right: none;
    display: block;
    height: auto;
    padding: 0;
    width: 100%; 
    margin-top: 2px;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-review-snapshot-block-container:after, .p-w-r .pr-review-snapshot .pr-review-snapshot-block-container:before{
    display: initial;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-histogram-msq-container .pr-review-snapshot-msq-histogram .pr-histogram-msq-slider {
    padding: 0px 10px 10px 10px;
  }
  #pr-reviewSnapShot .p-w-r .pr-slider-root.pr-text-above-root { 
    max-width: 100%;
  }
  #pr-reviewSnapShot .p-w-r .pr-slider-root .pr-slider.pr-text-above {
    flex: auto;
    width: 50%;
    margin-top: -21px;
  }
  
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-histogram-msq-container .pr-review-snapshot-msq-histogram .pr-histogram-msq-headline {
    font-size: 13px;
    line-height: inherit;
    margin-bottom: 0;
    padding: 0 10px;
    text-transform: uppercase;
    font-weight: 600;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-histogram-msq-container .pr-review-snapshot-msq-histogram {
    border-top: none !important;
  }
  #pr-reviewSnapShot .p-w-r .pr-slider-root .pr-slider .pr-slider-node-group .pr-slider-node.pr-active {
    background-color: #000; 
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced.pr-snapshot-mobile .pr-review-snapshot-block-container .pr-review-snapshot-block.pr-review-snapshot-block-recommend{
    display: none;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-simple .pr-review-snapshot-block.pr-review-snapshot-block-recommend{
    display: none;
  }
  #pr-reviewSnapShot .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-read-and-write a {
    margin: 0 5px 0 5px; 
    cursor: pointer;
  }
  #pr-reviewSnapShot .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-read-and-write span.pr-snippet-review-count {
    cursor: pointer;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-brandscore-bargraph-colorbar {
    background-color: #000000; 
    border-radius: 0px;
     
  }
  #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-brandscore-bargraph-fullbar {
    background-color: #f6f6f6; 
    border-radius: 0px;
    
  }
  /* .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-hist-filter-4 .pr-histogram-count{
    color:#000;
  }
  .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-histogram-count{
    color: #000;
  }
  .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-histogram-stars:hover .pr-ratings-histogram-barValue {
    background: #000000;
  }
   */
   #pr-reviewSnapShot  .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-header-contents:before{
     content: "Filter Reviews By";
     font-size: 1rem;
   }
   #pr-reviewSnapShot  .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-header-contents.nofilters:before{
    content: " "; 
  }
  #pr-reviewSnapShot  .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header-with-filters .pr-rd-review-header-sorts .pr-rd-sort{
    margin: 5px 0px 5px 0;
    min-width: 160px; 
    padding: 8px;
    border: 1px solid #d4d4d4;
    color: #838383;
   }
   #pr-reviewSnapShot .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-sort-group::before{
    content: "Sort By";
      font-size: 1rem;
      display: block;
      text-align: left;
   }
   #pr-reviewSnapShot .p-w-r .pr-multiselect.pr-multiselect-button-cons,#pr-reviewSnapShot .p-w-r .pr-multiselect.pr-multiselect-button-describeyourself,#pr-reviewSnapShot .p-w-r .pr-multiselect.pr-multiselect-button-bestuses,#pr-reviewSnapShot .p-w-r .pr-multiselect.pr-multiselect-button-pros,#pr-reviewSnapShot .p-w-r .pr-multiselect.pr-multiselect-button-chest,#pr-reviewSnapShot .p-w-r .pr-multiselect.pr-multiselect-button-height,#pr-reviewSnapShot .p-w-r .pr-multiselect.pr-multiselect-button-neck,#pr-reviewSnapShot .p-w-r .pr-multiselect.pr-multiselect-button-overallsize,#pr-reviewSnapShot .p-w-r .pr-multiselect.pr-multiselect-button-overallsize1,#pr-reviewSnapShot .p-w-r .pr-multiselect.pr-multiselect-button-weight,#pr-reviewSnapShot .p-w-r .pr-multiselect.pr-multiselect-button-length,.p-w-r .pr-multiselect.pr-multiselect-button-sizing,#pr-reviewSnapShot .p-w-r .pr-multiselect.pr-multiselect-button-sleevelength,.pr-multiselect.pr-multiselect-button-hipsrear,.pr-multiselect.pr-multiselect-button-overalllsize,.pr-multiselect.pr-multiselect-button-rise,.pr-multiselect.pr-multiselect-button-waistt,.pr-multiselect.pr-multiselect-button-chestsize{
    display: none;
  }
  
  #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-header-contents .pr-multiselect {
    display: none;
    
  }
  
  #pr-reviewSnapShot .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header-with-filters{
    
      width: 100%;
      display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    #pr-reviewSnapShot  .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header-with-filters .pr-rd-review-header-contents{
          order: 3; 
        margin-top: -6rem;
        margin-bottom: 1rem;
        width: 85%;
    }
    #pr-reviewSnapShot  .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-main-header-search-sort{
        padding-top: 0px;
      float: right;
      flex: 1 100%;
    }
    #pr-reviewSnapShot  .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header-with-filters .pr-rd-main-header-search-sort .pr-rd-main-header-search{
        width: 100%; 
      background-color: #f6f6f6;
      height: 60px;
    }
    
    #pr-reviewSnapShot  .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-main-header-search-sort .pr-rd-main-header-search .pr-rd-search-container {
      max-width: 40%;
      width: 40%;
      float: right;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header-with-filters .pr-rd-main-header-search-sort .pr-rd-main-header-sorts-w-search{
      float: right;
      text-align: right;
      width: 30%;
      margin-top: 2rem;
    }
    #pr-reviewSnapShot .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header-with-filters .pr-rd-review-total{ 
        display: none;
    }
    #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-header-contents {
      background-color: transparent;
      padding:15px 0 0 0;
      margin-top: 1.5rem;
      
    }
    #pr-reviewSnapShot .p-w-r .pr-review-display .pr-review-filter-info-bar{
      margin-top: 0px;
    }
    #pr-reviewSnapShot .p-w-r .pr-review-display .pr-review-filter-info-bar h1.pr-review-filter-headline{
      display: none;
    }
   
  
    #pr-reviewSnapShot .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header.pr-rd-main-header-with-filters{
      margin-bottom: 1rem;
    }
    #pr-reviewSnapShot .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header.pr-rd-main-header-with-filters div .pr-review-filter-info-bar{
      position: absolute;
      /* top: 27rem; */
      left: 0;
    }
    #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header.pr-rd-main-header-with-filters div .pr-review-filter-info-bar button.pr-filter-btn{
      display: none;
    }
    #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header.pr-rd-main-header-with-filters div .pr-review-filter-info-bar button.pr-review-filter-clear-all{
      display: inline-block;
      margin-left: 0px;
    }
  
    #pr-reviewSnapShot .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header-with-filters .pr-rd-review-total.pr-rd-no-results-msg {
      display: block;
  }
  #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-main-header-search-sort .pr-rd-main-header-sorts-w-search .pr-rd-sort-group:nth-child(2),#pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-main-header-search-sort .pr-rd-main-header-sorts-w-search .pr-rd-sort-group:nth-child(3),#pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-main-header-search-sort .pr-rd-main-header-sorts-w-search .pr-rd-sort-group:nth-child(4),#pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-main-header-search-sort .pr-rd-main-header-sorts-w-search .pr-rd-sort-group:nth-child(5){
    display: none;
  }
  
  #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header.pr-rd-main-header-with-filters .pr-accessible-text+div{
    width:100%;
    order:4;
  }
  
  #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header.pr-rd-main-header-with-filters .pr-rd-main-header-search-sort+h2+div{
    width:100%;
    order:3;
    margin-bottom: 2rem;
  }

  #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a{
    color: #000 !important;
 }
 #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a:hover{
    opacity: 0.8;
 }
 #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a:focus{
    border: 1px solid #43b0f4;
    box-shadow: 0 0 0 2px #43b0f4;
    outline: 2px solid transparent;
 }
 #pr-reviewSnapShot .p-w-r .pr-rd-review-header-contents .pr-multiselect-options li input[type="checkbox"]{
    height: 13px !important;
    width: 13px !important;
    border-radius: 3px;
 }
  
   @media screen and (min-width:768px){
    #pr-reviewSnapShot  .p-w-r .pr-slider-root .pr-slider-text-above .pr-min-text,.p-w-r .pr-slider-root .pr-slider-text-above .pr-max-text{
      /* width: 85px; */
    }
   }
   
  @media screen and (max-width:767.98px){
    #pr-reviewSnapShot  .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-histogram-msq-container {
        width: 94% !important;   
        padding: 15px !important;
    }
    #pr-reviewSnapShot  .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-simple .pr-review-snapshot-block{
        width: 94% !important; 
    }
    #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-simple .pr-review-snapshot-snippets-headline{
        display: block;
    }
    #pr-reviewSnapShot  .pr-review-snapshot-block-container.pr-review-snapshot-simple .pr-review-snapshot-block-snippet {
        padding: 15px !important;
        text-align: center;
    }
    #pr-reviewSnapShot  .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-rating-decimal {
        display: none;
    }
    #pr-reviewSnapShot  .pr-review-snapshot-snippets .pr-snippet .pr-snippet-stars-container,.pr-review-snapshot-snippets .pr-snippet .pr-snippet-read-and-write{
        text-align: center !important;
    }
    #pr-reviewSnapShot  .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-histogram-msq-container .pr-review-snapshot-msq-histogram .pr-histogram-msq-slider {
        padding: 5px 0px !important;
    }
    #pr-reviewSnapShot  .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-histogram-msq-container .pr-review-snapshot-msq-histogram .pr-histogram-msq-headline { 
        padding: 0 0px; 
        margin-top: 5px;
  
    }
    #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-simple .pr-review-snapshot-block.pr-review-snapshot-block-histogram{
      padding: 10px 0 !important;
    }
    #pr-reviewSnapShot .p-w-r .pr-slider-root .pr-slider.pr-text-above { 
      width: 100%;
      margin-top: 0px;
    }
    #pr-reviewSnapShot .p-w-r .pr-review-snapshot .pr-review-snapshot-simple.pr-review-snapshot-simple--brandscore .pr-review-snapshot-snippets-headline{
      display: none;
    }
    #pr-reviewSnapShot .p-w-r .pr-review-snapshot.pr-review-enhanced.pr-snapshot-mobile .pr-review-snapshot-simple .pr-review-snapshot-block.pr-review-snapshot-block-brandscore{
      margin: 10px;
      padding: 15px;
    }
  
    #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters{
    
        width: 100%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-header-contents{
          order: 3; 
          margin-top: -1rem;
          margin-bottom: 1rem;
          width: 100%;
      }
      #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-main-header-search-sort .pr-rd-main-header-search .pr-rd-search-container{
        margin-bottom: 10px;
      }
      #pr-reviewSnapShot .p-w-r .pr-read-review .pr-rd-main-header-with-filters .pr-rd-sort, .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort{
        width: 100%;
        border: 1px solid #8c8c8c;
        padding: 5px 40px 5px 5px;
        height: 34px!important;
        color: #838383;
        max-width: 100%;
      }
      #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-total{
        display: none;
      }
      #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-main-header-search-sort .pr-rd-main-header-search .pr-rd-search-container {
        max-width: 100%;
        width: 100%;
        float: right;
    }
    #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-sort-group::before{
      content: "Sort By";
        font-size: 1rem;
        display: block;
        text-align: left;
     }
     #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-header-contents .pr-multiselect { 
      /* width: 31.5%; */
      width: 48%;
      margin-right: 5px;
    }
    #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-header-contents .pr-multiselect .pr-multiselect-button { 
      margin: 5px 10px 5px 0;
      min-width: 100%; 
    }
  
    #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header.pr-rd-main-header-with-filters{
      margin-bottom: 1rem;
    }
    #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header.pr-rd-main-header-with-filters div .pr-review-filter-info-bar{
      position: absolute;
      /* top: 58.5rem; */
      left: 0;
    }
    #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header.pr-rd-main-header-with-filters div .pr-review-filter-info-bar .pr-filter-btn{
      display: none;
    }
    #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header.pr-rd-main-header-with-filters div .pr-review-filter-info-bar button.pr-review-filter-clear-all{
      display: inline-block;
      margin-left: 0px;
    }
  
    #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-header-contents {
      text-align: left;
      
    }
    #pr-reviewSnapShot .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-review-total.pr-rd-no-results-msg {
      display: block;
  }
  
  }
  
  .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header-with-filters .pr-rd-review-header-sorts .pr-rd-sort{
    color: #000;
  }

  #pr-reviewSnapShot-popup .pr-review-snapshot-block .pr-review-snapshot-block-recommend{
    display: none;
  }
  #pr-reviewSnapShot-popup  .p-w-r .pr-review-snapshot.pr-review-enhanced.pr-snapshot-mobile .pr-review-snapshot-histogram-msq-container
  #pr-reviewSnapShot-popup .p-w-r .pr-review-snapshot .pr-review-snapshot-faceoff{
    display: none;
  }

  #pr-reviewSnapShot-popup .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-histogram-msq-container{
    display: none;
  }
  header#pr-rd-main-header{
display: none;
  }
  #pr-reviewSnapShot-popup .p-w-r section #pr-review-display{
    display: none;
  }

  #pr-reviewSnapShot-popup .p-w-r section section div section section .pr-snippet .pr-snippet-read-and-write a{
    display: none;
  }

  .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block .pr-ratings-histogram-bar{
    border-radius: 0 !important;
  }
  .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block .pr-ratings-histogram-barValue{
    border-radius: 0 !important;
  }
  .p-w-r .pr-review-snapshot .pr-ratings-histogram .pr-ratings-histogram-barValue{
    background: #000 !important;
  }

  #pr-reviewSnapShot-popup .p-w-r section section div section section.pr-review-snapshot-snippets .pr-review-snapshot-snippets-headline{
    display: inline-block;
    margin-right: 1rem;
    vertical-align: top;
    font-size: 20px;
  }

  #pr-reviewSnapShot-popup .p-w-r section section div section section.pr-review-snapshot-snippets .pr-review-snapshot-snippets-headline{
    display: inline-block;
    margin-right: 1rem;
    vertical-align: top;
    font-size: 20px;
  }
  #pr-reviewSnapShot-popup .p-w-r section section div section.pr-review-snapshot-snippets .pr-review-snapshot-snippets-headline::after {
    content: " out of 5"; 
    display: inline-block; 
    margin-left: 5px;
  }

  #pr-reviewSnapShot-popup .p-w-r section section div section section.pr-review-snapshot-snippets .pr-snippet { 
    display: inline-block;  
  }
  #pr-reviewSnapShot-popup .p-w-r section section div section.pr-review-snapshot-block.pr-review-snapshot-block-recommend {
    display: none;
  }
  #pr-reviewSnapShot-popup .p-w-r section section div section section.pr-review-snapshot-snippets .pr-snippet div.pr-snippet-read-and-write span.pr-snippet-review-count{ 
    margin-left: -115px; 
  }
  #pr-reviewSnapShot-popup .p-w-r section section.pr-review-snapshot-snippets{
    margin-left: 15px;
    margin-top: 15px;
  }

  @media screen and (max-width:767.9px) {
    #pr-reviewSnapShot-popup .p-w-r section section.pr-review-snapshot-snippets{
        margin-left: 15px;
        margin-top: 0px;
      }
      #pr-reviewSnapShot-popup .p-w-r section section div section section.pr-review-snapshot-snippets .pr-snippet div.pr-snippet-read-and-write span.pr-snippet-review-count{ 
        margin-left: -240px; 
      }  

      #pr-reviewSnapShot-popup .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-simple .pr-review-snapshot-block{
        width: 100% !important;
      }
      #pr-reviewSnapShot-popup .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-review-count{
        margin-bottom: 0 !important;
      }

      #pr-reviewSnapShot-popup .pr-review-snapshot-block-container.pr-review-snapshot-simple .pr-review-snapshot-block-snippet {
        padding-bottom: 0 !important;
      }

      #pr-reviewsnippet .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-stars-container, .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-stars-container {
        display: inline-block !important;
    }
   #pr-reviewsnippet .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write {
        display: inline-block !important;
        
    }
  }
@media screen and (max-width:380px) {
    #pr-reviewsnippet .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write {
        padding-left: 7.5px;
    }
    #pr-reviewsnippet .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-reco{
        float: left !important;
    }
    #pr-reviewsnippet .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-stars {
        float: left !important;
        margin-top: 10px;
    }
    #pr-reviewsnippet .p-w-r .pr-snippet-reco-to-friend div.pr-snippet-reco-to-friend-percent{
      vertical-align: middle !important;
    }
}
  #pr-reviewsnippet .p-w-r section div div div div.pr-snippet-stars-container{
    cursor: pointer;
    margin-top: -1px;
  }
  #prQuickView #pr-reviewsnippet .p-w-r section div div div div.pr-snippet-read-and-write .pr-snippet-write-review-link{
    display: none;
  }

  #prQuickView #pr-reviewsnippet .p-w-r section div div div div.pr-snippet-read-and-write span[aria-label="Write the first review"]{
    display: none;
  }
  #pr-reviewsnippet .p-w-r .pr-snippet-reco-to-friend div.pr-snippet-reco-to-friend-percent{
    vertical-align: middle !important;
    margin-top: -3px;
  }

  #pr-reviewsnippet .p-w-r section div div div div.pr-snippet-read-and-write{
    cursor: pointer;
    margin-top: 2px;
  }
  #pr-reviewSnapShot-popup .p-w-r section section div section.pr-review-snapshot-block.pr-review-snapshot-block-histogram div.pr-review-snapshot-histogram ul li div div.pr-histogram-cross{ 
    display: none; 
  }

  #pr-reviewSnapShot-popup .p-w-r section section section.pr-review-snapshot-faceoff{ 
    display: none; 
  }

  .reviewedBy {
    display: block;
    max-width: 40%;
    width: 40%;
    float: left;
    padding-left: 15px !important;
    padding-top: 10px !important;
    @media screen and (max-width: 767.98px) {
      max-width: 100%;
      width: 100%;
      padding-left: 0px !important;
      padding-bottom: 15px !important;
      
  }
}

.reviewedBy h2 {
    font-size: 18px !important;
    font-weight: 700 !important;
}
#pr-reviewsnippet .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-stars-reco-reco
{
    margin-top: 0 !important;
}
